import React from "react";
import Layout from "modules/common/components/layout";
import ProjectPreview from "./project-preview";
import SEO from "../../common/components/seo";


const SoftwarePage = ({ title, projects, spiel }) => {

  return (
    <Layout>
      <SEO keywords={['software', 'reactjs', 'python', 'node', 'javascript']} title="software projects" />
      <section className={`w-full flex justify-center`}>
        <div className="max-w-4xl my-8">
          <div className="mx-4">
            <h1 className=" font-bold text-3xl mb-3">
              {title} Projects
            </h1>
            <p className="mb-8 sm:text-lg">{spiel}</p>
          </div>
          <div className="flex flex-col gap-10 sm:gap-4 mb-8">
            {projects.map(({ id, slug, title, description, childImageSharp }) => (
              <ProjectPreview
                key={id}
                slug={slug}
                title={title}
                description={description}
                childImageSharp={childImageSharp}
              />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SoftwarePage;
