import React from 'react'
import { Link } from "gatsby";
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const ProjectPreview = ({ slug, title, description, childImageSharp }) => {
    const imageData = getImage(childImageSharp)
    return (
        <Link to={slug} className="flex w-full p-4 flex-col sm:flex-row gap-3 sm:gap-4 hover:bg-gray-200">
            <div className="w-full sm:w-64">
                <GatsbyImage image={imageData} alt={`Preview image for ${title}`} />
            </div>
            <div className="w-full">
                <h3 className="text-xl font-bold mb-1">{title}</h3>
                <p>{description}</p>
            </div>
        </Link>
    );
}

export default ProjectPreview;