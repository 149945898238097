import React from 'react';
import { graphql } from "gatsby";

import SoftwarePage from "modules/software/components/software-page"
import { DISPLAY_NAMES } from '../modules/common/api/constants';

const SoftwarePageWrapper = ({ data: { allFile, markdownRemark: { frontmatter: { softwareIntro } }, allMarkdownRemark: { edges } } }) => {
  const imgHash = {}
  allFile.edges.forEach(edge => {
    const imgPath = edge.node.absolutePath.replace(/^.*\/static/, '')
    imgHash[imgPath] = edge.node.childImageSharp
  })
  const cleanProjects = edges.map(({ node: { id, fields: { slug }, frontmatter: { title, projectdate, featuredimage, description } } }) => ({ id, slug, title, projectdate, description, childImageSharp: imgHash[featuredimage] }));
  return (
    <SoftwarePage title={DISPLAY_NAMES.DEV} spiel={softwareIntro} projects={cleanProjects} />
  );
}

export const pageQuery = graphql`
query DevProjectsPageQuery {
  allMarkdownRemark(
    filter: {frontmatter: {category: {eq: "software"}}}
    sort: {fields: [frontmatter___projectdate], order: DESC}
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          projectdate
          featuredimage
          description
        }
        fields {
          slug
        }
      }
    }
  }
  markdownRemark(fileAbsolutePath: {glob: "**/pages/software.md"}, frontmatter: {}) {
    id
    frontmatter {
			softwareIntro
    }
  }
  allFile(
    filter: {absolutePath: {glob: "**/img/*.{jpg,jpeg,png}"}}
  ) {
    edges {
      node {
        id
        absolutePath
        childImageSharp {
          gatsbyImageData(
            width: 620,
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
}


`;

export default SoftwarePageWrapper;
